/* Tooltip Module */

// Tooltip
.tooltip {
	display: inline-block;
	filter: drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 10px);
  position: absolute;
	right: 9rem;
	transition: all 2s ease;
	z-index: 10;
	&__text {
		background-color: white;	
		border-radius: 1rem;
		padding: .5rem 0;
		position: absolute;
		text-align: center;
		width: 7rem;
		&::after { 
			border-width: 1rem;
			border-style: solid;
			border-color: transparent transparent transparent white;
			content: " ";
			left: calc(100% - .5rem); 
			margin-top: -1rem;
			position: absolute;
			top: 50%;
		}
	}
	&__time {
		font-size: 1.375rem;
	}
	&__period {
		font-size: 1rem;
	}
}

// Provide a percentage modifier class
@for $i from 1 through 100 {
  .tooltip-#{$i} {
		$value: ($i * 1%);
		bottom: $value;
  }
}
