/* Header Module */

// Header
.header {
  align-items: center;
  color: black;
  fill: black;
  grid-area: header;
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem;
  }
  &__menu {
    justify-self: flex-start;
  }
  &__logo {
		display: flex;
    flex-grow: 1;
    justify-content: center;
  }
  &__search {
    justify-self: flex-end;
  }
  svg {
    fill: black;
  }
}