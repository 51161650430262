/* Wave Module */

// Imports
@import "../../styles/_colors.scss";  

// Wave
.wave {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 1.625rem;
  font-weight: bold;
  height: 70vh;
  min-height: 20rem;
  text-transform: uppercase;
  width: 100vw;
  &__header {
    box-sizing: border-box;
    display: flex;
    padding: 1rem 1rem 0 1rem;
    width: 100%;
  }
  &__header-tide {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
  }
  &__tide-direction {
    &.high {
      transform: rotate(0deg);
      transition: 1s ease-in-out;
    }
    &.low {
      transform: rotate(180deg);
      transition: 1s ease-in-out;
    }
  }
  &__tide-height {
    padding-left: .25rem;
    
  }
  &__header-unit {
    font-size: 0.875rem;
    font-weight: bold;
    padding-bottom: .2rem;
    padding-left: .2rem;
  }
  &__header-label {
    flex-grow: 1;
    text-align: right;
  }
  &__container {
    height: 100%;
  }
  &__footer {
    background-color: #1B998F; 
    color: #fff;
    display: flex;
    justify-content: flex-end;
    padding: 0 1rem 1rem 1rem;
  }
}

// Percentage
.percentage {
	//  background: repeating-linear-gradient( to top, white, white 1px, transparent 1px,transparent 8.33% );
  font-size: .8em;
  height: 100%;
  line-height: 1;
  margin: 0;
	position: relative;
  text-transform: uppercase;
  width: 100%;
  &:after {
    background: url("/media/wave-small.svg");
		background-repeat: repeat-x;
    content: "";
    cursor: pointer;
    display: block;
    height: 90%;
    position: relative;
    top: 100%;
    transform: translateY(-100%);
		transition: all 2s ease;
    width: 100%;
  }
}

// Provide a percentage modifier class
@for $i from 1 through 100 {
  .percentage-#{$i} {
    &:after {
      $value: ($i * 1%);
      height: $value;
    }
  }
}

