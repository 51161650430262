/* Homepage Module */

// Homepage Template
.homepage {
	display: grid;
	height: 100vh;
  grid-template-areas:
    'header'
    'main'
    'footer';
	max-width: 100vw;
	overflow: hidden;
	position: relative;
  width: 100vw;
}

// Main Section
.main {
	align-items: center;
	display: flex;
	justify-content: center;
	flex-grow: 1;
	grid-area: main;
}

// Branding
.branding {
	align-items: center;
	display: flex;
	flex-direction: column;
	grid-area: header;
	justify-content: center;
	z-index: 4;
}

