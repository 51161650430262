/* Footer Module */

// Imports
@import "../../styles/_colors.scss";  

// Footer
.footer {
	align-items: center;
	align-self: flex-end; 
	display: flex;
	flex-direction: column;
	grid-area: footer;
	justify-content: center;
	z-index: 10;
	&_content {
		color: $white;
		font-size: 0.8125rem;
		letter-spacing: .15em;
		padding: 2rem;
		text-align: center;
		text-transform: uppercase;
	}
	&_content-middle {
		font-size: 1.1rem;
		font-weight: bold;
		padding-bottom: .5rem;
		padding-top: .5rem;
	}
}