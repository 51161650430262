/* Form Module */

// Imports
@import "../../styles/_colors.scss";  

// Station Form
.station-form {
  border-bottom: 2px solid $white;
  padding: .25rem 0;
  position: relative;
  z-index: 11;
  &__input {
    background: transparent;
    border: none;
    color: $white;
    font-size: 1.5rem;
    font-style: italic;
    min-width: 24rem;
    &::placeholder {
      color: $white;
    }
  }
  &__button {
    background: none;
    border: none;
    padding: 0;
  }
  svg g { 
    stroke: #fff;
  } 
	&__error {
		bottom: -3rem;
		color: white;
		font-style: italic;
		font-size: 1rem;
    position: absolute;
	}
}

// Station Autocomplete
.autocomplete {
	position: relative;
	display: inline-block;
	&__items {
		border: 1px solid #d4d4d4;
		border-bottom: none;
		border-top: none;
		left: 0;
		position: absolute;
		right: 0;
		top: 100%;
		z-index: 99;
	}
	&__item {
		background-color: #fff;
		border-bottom: 1px solid #d4d4d4;
		cursor: pointer;
		padding: .625rem;
		text-transform: capitalize;
		&--hidden {
			display: none;
		}
	}
}
