/* Tide Module */

// Imports
@import "../../styles/_colors.scss";  

// Tide
.tide {
  display: grid;
  height: 100vh;
  grid-template-areas:
    'header'
    'main';
  max-width: 100vw;
  position: relative;
  width: 100vw;
}

// Main
.main {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  grid-area: main;
}
