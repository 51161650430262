/* Waves Module */

// Waves
.waves {
	animation: waves 21s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
	background: url(/media/wave-large.svg) repeat-x;
	bottom: 0;
	height: 80vh;
	min-width: 200vw;
	position: absolute;
	right: 0;
	transform: translate3d(0, 0, 0);
	width: 875rem;
	z-index: 0;
	&:nth-of-type(2){
		animation: waves 21s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 14s ease -1.25s infinite;
		height: 78vh;
		opacity: 1;
		z-index: 2;
	}
	&:nth-of-type(3){
		animation: waves 20s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.15s infinite, swell 18s ease -1.5s infinite;
		opacity: 1;
		z-index: 6;
	}
}

// Wave Animation
@keyframes waves {
  0% {
  	margin-right: 0;
  }
  100% {
    margin-right: -840px;
  }
}

// Swell Animation
@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,5px,0);
  }
}